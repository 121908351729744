<template>
	<div class="contributeRecord">
		<w-navTab titleText="缴纳记录"></w-navTab>
		<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
		<div class="nav"><w-tab :active="active" :data="loveData" ></w-tab></div>
			<div class="list-shaky" v-for="item in complete">
				<div class="list-title">{{ item.name }}</div>
				<div class="list-bottom">
					<div class="list-bottom-left">
						<div class="left-picture"><img src="@/assets/img/test246.png" alt="" /></div>
						<div class="left-time">{{ item.overdue_time }}</div>
					</div>
					<div class="list-bottom-right">-{{ item.amount }}元</div>
				</div>
			</div>
		</VantList>
	</div>
</template>

<script>
import WTab from '@/components/Tab';
import UserApi from '@/api/user';
import VantList from '@/components/VantList';
export default {
	name: 'ContributeRecord',
	data() {
		return {
			loading: true,
			finished: false,
			isEmpty: false,
			active: 0,
			loveData: [
				{
					title: '缴纳记录'
				}
			],
			page: 1,
			limit: 10,
			complete: []
		};
	},
	created() {
		this.onLoad()
	},
	methods: {
		onLoad() {
			this.loading = true;
		
			let params = {
				page: this.page,
				limit: this.limit
			};
			UserApi.userMembershipRecord(params)
				.then(result => {
					this.page++;
					this.complete = this.complete.concat(result.data.data)
					this.loading = false;
					if (this.complete.length <= 0) {
						this.isEmpty = true;
					}
					if (this.complete.length >= result.data.total) {
						this.finished = true;
					}
				})
				.catch(error => {});
		}
	},
	components: {
		WTab,
		VantList
	}
};
</script>

<style lang="less" scoped>
.contributeRecord {
	.nav{
		padding-top: 10px;
		padding-left: 10px;
	}
	.list-view {
		padding: 0 10px;
	}
	.list-shaky {
		padding: 15px 10px;
		background: #fff;
		border-radius: 8px;
		margin-top: 10px;
		.list-title {
			font-size: 18px;
			line-height: 24px;
			color: #333;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			margin-bottom: 13px;
		}
		.list-bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.list-bottom-left {
				display: flex;
				align-items: center;
				.left-picture {
					width: 16px;
					height: 16px;
					margin-right: 2px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.left-time {
					font-size: 13px;
					line-height: 16px;
					color: #666;
				}
			}

			.list-bottom-right {
				font-size: 13px;
				line-height: 16px;
				color: #f43e3d;
			}
		}
	}
}
</style>
